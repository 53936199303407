@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  box-sizing: border-box;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-item1 {
  background: url("../public/img/about-bg.png") center center no-repeat;
  background-size: cover;
  height: 500px;
  width: 100%;

  @media screen and (max-width: 991px) {
    height: 700px;
  }

  @media screen and (max-width: 767px) {
    height: 800px;
  }
}

.bg-item1-shrink {
  background: url("../public/img/about-bg.png") center center no-repeat;
  background-size: cover;
  height: 300px;
  width: 100%;

  @media screen and (max-width: 991px) {
    height: 300px;
  }

  @media screen and (max-width: 767px) {
    height: 400px;
  }
}